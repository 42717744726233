<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.released_order')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="certificate-generate-list" :class="'btn btn-success text-light'">{{ $t('elearning_tim.released_order') }} {{ $t('globalTrans.list') }}</router-link>
                    </template>
                    <template v-slot:body>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <b-row>
                            <!-- <b-col lg="6" sm="6">
                              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="circular_memo_no"
                                      v-model="formData.check_time"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :placeholder="$t('elearning_iabm.circular_memo_no')"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  <div class="invalid-feedback d-block" v-if="circularErrorMsg">
                                    {{ circularErrorMsg }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <!-- <b-col lg="6" sm="6">
                              <ValidationProvider name="Payment Type" vid="payment_type" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="payment_type"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('elearning_iabm.registration_for')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.registration_for"
                                      :options="userTypeList"
                                      id="payment_type"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      </b-form-select>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                          </b-row>
                          <b-overlay :show="load">
                            <b-row>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="fiscal_year_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-select
                                        plain
                                        v-model="formData.fiscal_year_id"
                                        :options="fiscalYearList"
                                        id="fiscal_year_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="org_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $t('elearning_config.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-select
                                        plain
                                        v-model="formData.org_id"
                                        :options="orgList"
                                        id="org_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select> -->
                                  <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <!-- <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                                <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="org_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.org_id"
                                        :options="orgList"
                                        id="org_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="office_type_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.office_type_id"
                                        :options="officeTypeList"
                                        id="office_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="office_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.office_id"
                                        :options="officeList"
                                        id="office_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col> -->
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="training_type_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-select
                                      plain
                                      v-model="formData.training_type_id"
                                      :options="trainingTypeList"
                                      id="training_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_category_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-select
                                      plain
                                      v-model="formData.training_category_id"
                                      :options="trainingCategoryList"
                                      id="training_category_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_title_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                    </template>
                                    <!-- <b-form-select
                                      plain
                                      v-model="formData.training_title_id"
                                      :options="trainingTitleList"
                                      id="training_title_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Batch" vid="batch_no" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="batch_no"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.batch_no"
                                        :options="batchList"
                                        id="batch_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="circular_memo_no"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.circular_memo_no"
                                        :options="circularList"
                                        id="circular_memo_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-overlay :show="loading">
                              <b-row v-if="formData.details.length > 0">
                                <b-col md="6" class="mb-2">
                                  <b-form-checkbox
                                    style="margin-top: 5px"
                                    class="mb-3"
                                    name="checked"
                                    v-model="isCheckAll"
                                    @change="checkAll"
                                    :value=true
                                    :unchecked-value=false
                                  >
                                  <span>{{ $t('globalTrans.checkAll') }}</span>
                                  </b-form-checkbox>
                                </b-col>
                              </b-row>
                              <b-row>
                                <div class="col-md-12">
                                  <fieldset class="p-2 w-100">
                                    <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.released_order')}} {{$t('globalTrans.list')}}</legend>
                                      <table class="table" style="width:100%">
                                        <thead class="thead">
                                          <tr>
                                            <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                                            <th>{{$t('globalTrans.name')}}</th>
                                            <th>{{$t('globalTrans.organization')}}</th>
                                            <th>{{$t('globalTrans.designation')}}</th>
                                            <th>{{$t('elearning_tim.posting_office')}}</th>
                                            <!-- <th>{{$t('globalTrans.mobile')}}</th>
                                            <th>{{$t('globalTrans.email')}}</th> -->
                                            <th>{{$t('globalTrans.select')}}</th>
                                          </tr>
                                        </thead>
                                        <tr v-for="(train,index) in formData.details" :key="index">
                                          <td class="text-center">{{$n(index+1)}}</td>
                                          <td>
                                            {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                          </td>
                                          <td>
                                            <span v-if="train.profession_type == 1">{{ ($i18n.locale==='bn') ? train.org_name_bn : train.org_name }}</span>
                                            <span v-else>{{ ($i18n.locale=='bn') ? train.professional_other_org_name_bn : train.professional_other_org_name }}</span>
                                          </td>
                                          <td>
                                            <span v-if="train.designation_id && train.not_here_designation == 0 && train.profession_type == 1">{{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}</span>
                                            <span v-else>{{ ($i18n.locale=='bn') ? train.designation_bn : train.designation_en}}</span>
                                          </td>
                                          <td>
                                            <span v-if="train.profession_type == 1 && train.not_here_office == 0">{{ getOfficeName(train.professional_office_id) + ', ' + getOrgName(train.professional_org_id) }}</span>
                                            <span v-if="train.profession_type == 1 && train.not_here_office == 1">{{ ($i18n.locale=='bn') ? train.office_name_bn : train.office_name }}</span>
                                            <span v-if="train.profession_type != 1">{{ ($i18n.locale=='bn') ? train.office_name_bn : train.office_name }}</span>
                                          </td>
                                          <!-- <td>
                                            {{ train.mobile }}
                                          </td>
                                          <td>
                                            {{ train.email }}
                                          </td> -->
                                          <td>
                                            <b-form-checkbox
                                              :id="'checkbox-' + train.id"
                                              v-model="train.sort_status"
                                              :name="'checkbox-' + train.id"
                                              value=1
                                              unchecked-value=0
                                            >
                                            </b-form-checkbox>
                                            <small v-if="detailsErrors && detailsErrors['details.' + index + '.select']" class="text-danger">
                                              {{ detailsErrors['details.'+index+'.select'] }}
                                            </small>
                                          </td>
                                          <td>
                                          </td>
                                        </tr>
                                        <template v-if="formData.details.length === 0">
                                          <tr>
                                            <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                          </tr>
                                        </template>
                                      </table>
                                  </fieldset>
                                </div>
                              </b-row>
                            </b-overlay>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                  &nbsp;
                                  <router-link to="certificate-generate-list" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                              </div>
                            </div>
                          </b-overlay>
                        </b-form>
                      </ValidationObserver>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { certificateApplicant, certificateStore, circularPublication, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
      return {
        search: {
          org_id: 0,
          mobile: ''
        },
        formData: {
          registration_for: 2,
          check_time: '',
          circular_memo_no: '',
          coordinator_id: 0,
          batch_no: 0,
          fiscal_year_id: null,
          fiscal_year_en: '',
          office_type_id: 0,
          office_id: 0,
          org_id: null,
          training_category_id: null,
          training_type_id: null,
          training_title_id: null,
          sort: 3,
          details: [
          ]
        },
        trainingTypeList: [],
        allBatchListData: [],
        batchList: [],
        applicant: [],
        officeTypeList: [],
        officeList: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        load: false,
        loading: false,
        detailsErrors: [],
        selectedItem: [],
        selectAll: false,
        isCheckAll: false,
        circularList: [],
        circularLoading: false,
        circularErrorMsg: false,
        trainerProfileError: '',
        myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
      }
    },
    created () {
      this.formData = Object.assign({}, this.formData, {
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
      this.getFiscalYearName()
    },
    computed: {
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      }
    },
    watch: {
      'formData.org_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.formData.training_type_id = null
          this.trainingTypeList = this.getTypeList(newValue)
        } else {
          this.trainingTypeList = []
        }
      },
      'formData.training_type_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.formData.training_category_id = null
          this.trainingCategoryList = this.getCategoryList(newValue)
        } else {
          this.trainingCategoryList = []
        }
      },
      'formData.training_category_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.formData.training_title_id = null
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        } else {
          this.trainingTitleList = []
        }
      },
      'formData.fiscal_year_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.formData.circular_memo_no = ''
          this.formData.batch_no = 0
          this.getCircularList()
          this.getSheduleList()
          this.getFiscalYearName()
        }
      },
      'formData.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.formData.circular_memo_no = ''
          this.formData.batch_no = 0
          this.getCircularList()
        }
      },
      'formData.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.formData.batch_no = allBatchListData.batch_no
          }
          this.loadData()
        }
      },
      'formData.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.formData.circular_memo_no = allBatchListData.circular_memo_no
          }
          this.loadData()
        }
      }
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      checkAll () {
          const list = this.formData.details.map(item => {
              if (this.isCheckAll) {
                  item.sort_status = 1
              } else {
                  item.sort_status = 0
              }
              return Object.assign({}, item)
          })
          this.formData.details = list
      },
      getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.currentLocale === 'bn') {
          return office !== undefined ? office.text_bn : ''
        } else {
          return office !== undefined ? office.text_en : ''
        }
      },
      getOrgName (id) {
        const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
        if (this.currentLocale === 'bn') {
          return trainingType !== undefined ? trainingType.abbreviation_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.abbreviation : ''
        }
      },
      getTypeList (orgId) {
        if (this.formData.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (orgId) {
            return type.filter(item => item.org_id === parseInt(orgId))
          }
          return type
        }
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.formData.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        if (this.formData.fiscal_year_id && this.formData.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.formData.fiscal_year_id,
            training_type_id: this.formData.training_type_id,
            training_category_id: this.formData.training_category_id,
            training_title_id: this.formData.training_title_id,
            coordinator_id: this.formData.coordinator_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
            this.allBatchListData = []
          } else {
            this.allBatchListData = result.batchList
            this.batchInfo()
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
      async getCircularMemoNo (getCircularMemoNo) {
        if (getCircularMemoNo) {
          this.load = true
          const params = {
            circular_memo_no: getCircularMemoNo,
            table: 'iab_circular_publications'
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
          if (!result.success) {
            this.formData.fiscal_year_id = 0
            this.formData.org_id = 0
            this.formData.office_id = 0
            this.formData.office_type_id = 0
            this.formData.training_title_id = 0
            this.formData.training_type_id = 0
            this.formData.training_category_id = 0
            this.formData.sort = 3
            this.formData.details = []
            this.circularErrorMsg = 'Please Create Circular'
            this.load = false
          } else {
            this.circularErrorMsg = false
            const trData = result.data
            this.formData.fiscal_year_id = trData.fiscal_year_id
            this.formData.org_id = trData.org_id
            this.formData.office_id = trData.office_id
            this.formData.office_type_id = trData.office_type_id
            this.formData.training_title_id = trData.training_title_id
            this.formData.training_type_id = trData.training_type_id
            this.formData.training_category_id = trData.training_category_id
            this.load = false
            this.loadData()
          }
        }
      },
      async loadData () {
        this.loading = true
        const searchData = this.formData
        searchData.details = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateApplicant, searchData)
        if (result.success) {
          this.applicant = result.applicant
          const datas = result.data.map(item => {
            const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            // const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
            // const officeData = {}
            // if (typeof officeObj !== 'undefined') {
            //   officeData.office_name = officeObj.text_en
            //   officeData.office_name_bn = officeObj.text_bn
            // } else {
            //   officeData.office_name = ''
            //   officeData.office_name_bn = ''
            // }
            const applicantObj = this.applicant.find(applicant => applicant.training_application_id === parseInt(item.id))
            const applicantData = {}
            if (typeof applicantObj !== 'undefined') {
              applicantData.sort_status = 1
            } else {
              applicantData.sort_status = 0
            }
            return Object.assign({}, item, desigData, applicantData, orgData)
          })
          this.formData.details = datas
        } else {
          this.formData.details = []
        }
        this.loading = false
      },
      getFiscalYearName () {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(this.formData.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.formData.fiscal_year_en = fiscalYearObj.text_en
        } else {
          this.formData.fiscal_year_en = ''
        }
      },
      getOfficeTypeList (orgId) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      },
      getOfficeList (officeTypeId) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      },
      getCategoryList (typeId) {
        if (this.formData.training_type_id) {
          const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
          if (typeId) {
            return trainingCategoryList.filter(item => item.training_type_id === typeId)
          }
          return trainingCategoryList
        }
      },
      getTrainingTitleList (categoryId) {
        if (this.formData.training_type_id) {
          const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
          if (categoryId) {
            return trainingTitleList.filter(item => item.training_category_id === categoryId)
          }
          return trainingTitleList
        }
      },
      async createData () {
        this.load = true
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, certificateStore, this.formData)
        if (result.success) {
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$refs.form.reset()
          this.$router.push('/training-e-learning-service/tim/certificate-generate-list')
        }
        this.load = false
      }
    },
    beforeDestroy () {
      clearInterval(this.inputTimer)
    }
}
</script>
